import * as React from 'react'
import { graphql } from 'gatsby'
import { nanoid } from 'nanoid'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

import Container from '../components/Common/Container'
import Headings from '../components/Common/Headings'
import ColoredHeading from '../components/Common/ColoredHeading'
import ButtonLink from '../components/Common/ButtonLink'

import Seo from '../components/Seo'

import { useContent } from '../hooks/useContent'
import { getThumbnail } from '../helpers/thumbnail'

import RightChevron from '../assets/images/icons/svg/icon-right-chevron-white.svg'

const Careers = ({ data }) => {
  const content = useContent(data)

  let orderedCategories = content.openPositions.categories.sort(
    (a, b) => a.order - b.order
  )

  orderedCategories = orderedCategories.map(v => {
    return {
      ...v,
      list: [
        ...content.list
          .filter(l => l.category === v.name)
          .sort((a, b) => a.order - b.order),
      ],
    }
  })

  orderedCategories = orderedCategories.filter(v => v.list.length > 0)

  return (
    <>
      <Seo title={content.title} slug={'/careers'} />
      <div className="w-full mt-10 lg:mt-20">
        <Container>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-start items-center">
            <div className="whitespace-nowrap text-secondary">
              <Headings>
                <ColoredHeading text={content.heading} />
              </Headings>
            </div>

            <p className="typo-medium-16 text-gray lg:ml-10 text-center lg:text-left mt-4 lg:mt-0">
              {content.subHeading}
            </p>
          </div>
        </Container>

        <div className="bg-gray-light py-10 lg:py-20 mt-10 lg:mt-20">
          <Container>
            <div className="flex flex-col items-center justify-start">
              <Headings level={2}>
                <ColoredHeading text={content.howToJoin.title} />
              </Headings>
              <div className="w-full bg-white mt-6 lg:mt-10 p-10">
                <div className="flex flex-col lg:flex-row justify-between items-center">
                  {content?.howToJoin?.list.map(
                    ({ title, thumbnail, description }, index, all) => (
                      <React.Fragment key={`how-to-join-item-${nanoid()}`}>
                        <div className="flex flex-col justify-start items-center max-w-[260px] relative">
                          <div className="w-[70px] lg:w-[120px]">
                            <GatsbyImage
                              loading="eager"
                              image={getThumbnail(content, thumbnail)}
                              alt={title}
                            />
                          </div>
                          <p className="typo-medium-16 lg:typo-medium-20 text-secondary mt-4 text-center">
                            {title}
                          </p>
                          <p className="typo-medium-14 text-gray mt-2 text-center">
                            {description}
                          </p>
                        </div>
                        {index < all.length - 1 && (
                          <div
                            key={`how-to-join-item-${nanoid()}`}
                            className="lg:flex-auto my-[30px]"
                          >
                            <div className="h-[120px] w-px lg:w-full lg:h-px bg-gray-dark relative">
                              {(index === 0 || index === all.length - 2) && (
                                <span
                                  className={classNames(
                                    'absolute w-2 h-2 -left-[3.5px] lg:top-1/2 lg:-translate-y-1/2 bg-gray-dark rounded-full',
                                    {
                                      'top-0 lg:left-0': index === 0,
                                      'bottom-0 lg:left-auto lg:right-0':
                                        index === all.length - 2,
                                    }
                                  )}
                                ></span>
                              )}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="py-10 lg:py-20">
          <Container>
            <div className="flex flex-col items-center justify-start">
              <Headings level={2}>
                <ColoredHeading text={content.ourPerksAndBenefits.title} />
              </Headings>
              <div className="w-full mt-6 lg:mt-10">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 lg:gap-10">
                  {content?.ourPerksAndBenefits?.list.map(
                    ({ title, thumbnail, description }) => (
                      <div
                        key={`our-perks-benefits-item-${nanoid()}`}
                        className="flex flex-col justify-start items-center border-2 border-solid border-gray-dark rounded-lg px-5 py-5 lg:px-6 lg:py-4"
                      >
                        <div>
                          <GatsbyImage
                            image={getThumbnail(content, thumbnail)}
                            alt={title}
                            loading="eager"
                          />
                        </div>
                        <p className="typo-medium-16 lg:typo-medium-20 text-secondary mt-5 text-center">
                          {title}
                        </p>
                        <p className="typo-medium-14 lg:typo-medium-16 text-gray mt-2 lg:mt-4 text-center">
                          {description}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div>
          <Container>
            <div className="flex flex-col items-start justify-start">
              <Headings level={2} underline={true}>
                <ColoredHeading text={content.openPositions.title} />
              </Headings>
              <div className="w-full mt-8 lg:mt-10">
                <div className="grid grid-cols-1 gap-8 lg:gap-16">
                  {orderedCategories.map(({ title, list }) => (
                    <div key={title}>
                      <Headings
                        level={3}
                        className="typo-bold-24 lg:typo-bold-40 border-b-2 border-solid border-gray-dark pb-3 lg:pb-5 mb-8"
                      >
                        {title}
                      </Headings>

                      {list.map(({ heading, summary, infos, slug }, index) => (
                        <div
                          key={`career-item-${nanoid()}`}
                          className={classNames(
                            'grid grid-cols-4 lg:grid-cols-12 gap-[18px] lg:gap-10',
                            {
                              'pb-8 lg:pb-6 mb-8 lg:mb-6 border-b border-solid border-gray-dark':
                                index < list.length - 1,
                            }
                          )}
                        >
                          <div className="col-span-4 lg:col-span-6">
                            <p className="typo-medium-16 lg:typo-medium-20 text-secondary text-left">
                              {heading}
                            </p>
                            <p className="typo-medium-14 lg:typo-medium-16 text-gray mt-2 lg:mt-4 text-left">
                              {summary}
                            </p>
                          </div>
                          <div className="col-span-2 lg:col-span-3">
                            <p className="typo-medium-16 lg:typo-medium-20 text-secondary text-left">
                              {infos.location.label}
                            </p>
                            <p className="typo-medium-14 lg:typo-medium-16 text-gray mt-2 lg:mt-4 text-left">
                              {infos.location.value}
                            </p>
                          </div>
                          <div className="col-span-2 lg:col-span-3 flex justify-end items-center">
                            <ButtonLink
                              text="Apply Now"
                              icon={<RightChevron />}
                              to={`${slug}`}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($content: String = "careers", $list: String = "career") {
    allMarkdownRemark(filter: { frontmatter: { content: { eq: $content } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            heading
            subHeading
            howToJoin {
              title
              list {
                description
                thumbnail
                title
              }
            }
            ourPerksAndBenefits {
              title
              list {
                description
                thumbnail
                title
              }
            }
            openPositions {
              title
              categories {
                name
                title
                order
              }
            }
          }
        }
      }
    }

    list: allMarkdownRemark(
      filter: { frontmatter: { content: { eq: $list } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            heading
            summary
            slug
            infos {
              location {
                label
                value
              }
            }
            category
          }
        }
      }
    }

    images: allFile(
      filter: { relativeDirectory: { regex: "/(images/careers)/" } }
    ) {
      edges {
        node {
          relativePath
          name
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              width: 120
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
              breakpoints: [640, 768, 1024, 1280]
            )
          }
        }
      }
    }
  }
`

export default Careers
